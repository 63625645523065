/* eslint-disable react/jsx-key */
import "./Calendar.scss"

import React, {useEffect, useRef, useState} from "react"
import {Calendar} from "react-multi-date-picker"
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import {useMediaQuery} from "react-responsive"

import moment from "moment"

import {useCalendar} from "../../hooks"

import {CustomButton} from "./CustomButton"

import {DAYS_PER_WEEK, DESKTOP_WIDTH, MOBILE_WIDTH} from "../../constants/other"
import {positionTooltip} from "../../utils/timeoff.utils"
import useCurrentUserId from "../../hooks/useCurrentUserId"
import {endOfMonth, startOfMonth} from "date-fns"
import {formatDate} from "../../utils/datetime.utils"


const Calendars = ({
    day = new Date(),
    noToolbar = false,
    onCalendarBoundariesChange = null,
    ...props
}) => {
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const maxDate = new Date().setFullYear(new Date().getFullYear() + 1)

    const userId = useCurrentUserId() // This doesn't really belong here but let's change it later
    const [calendarDateBoundaries, setCalendarDateBoundaries] = useState({
        startDate: formatDate(moment(startOfMonth(new Date)).add(-DAYS_PER_WEEK, "days")),
        endDate: formatDate(moment(endOfMonth(new Date)).add(-DAYS_PER_WEEK, "days")),
    })
    const {fillCalendar, getTypeOfDay} = useCalendar({ userId, startDate: calendarDateBoundaries.startDate, endDate: calendarDateBoundaries.endDate })

    useEffect(() => onCalendarBoundariesChange?.(calendarDateBoundaries), [calendarDateBoundaries])

    const calendarRef = useRef()
    const tooltipRef = useRef(null)
    const [data, setData] = useState({})
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const CustomDatePickerHeader = ({state}) => (
        <div className="datepicker-plugin">
            <span className="datepicker-plugin-type t-h3">
                {state.value &&
                    getTypeOfDay(state.value, state.today)}
            </span>

            <span className="datepicker-plugin-weekday t-s3">
                {moment(state.value).format("dddd")}
            </span>

            <span className="datepicker-plugin-day t-h1">
                {moment(state.value).format("DD")}
            </span>

            <span className="datepicker-plugin-date t-s3">
                {moment(state.value).format("MMM YYYY")}
            </span>
        </div>
    )

    const calendarPlugins = [
        noToolbar ? [] :
            <Toolbar
                position="bottom"
                sort={["today"]}
                names={{today: "TODAY"}}
            />,
        ...(!isMobile ? [<CustomDatePickerHeader position="left"/>] : [])
    ]

    useEffect(() => {
        positionTooltip(tooltipRef, data)
    }, [data])


    const onChange = (date) => {
        props.onChange(date)
        setCalendarDateBoundaries({
            startDate: formatDate(moment(startOfMonth(date.toDate())).add(-DAYS_PER_WEEK, "days")),
            endDate: formatDate(moment(endOfMonth(date.toDate())).add(-DAYS_PER_WEEK, "days")),
        })
    }

    return (
        <>
            <Calendar
                ref={calendarRef}
                containerStyle={{ position: "relative" }}
                mapDays={({date, today, selectedDate, isSameDate}) => {
                    return fillCalendar(date, today, selectedDate, isSameDate, day, false, true, true, data, setData)
                }}
                maxDate={maxDate}
                weekStartDayIndex={1}
                value={day}
                onChange={onChange}
                renderButton={<CustomButton onCalendarBoundariesChange={(startDate, endDate) => setCalendarDateBoundaries({startDate, endDate})} calendarRef={calendarRef}/>}
                plugins={calendarPlugins}
                showOtherDays
            >
                {isDesktop &&
                <span
                    ref={tooltipRef}
                    style={{
                        visibility: data.visible ? "visible" : "hidden",
                    }}
                    className="calendar-tooltip"
                >
                    {data.title}
                </span>}
            </Calendar>
        </>
    )
}
export default Calendars
