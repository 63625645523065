import React from "react"
import "../index.scss"

import {deleteBonusById} from "../api"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"


const DeleteBonusPopup = ({currentBonus, setUserMessage, refreshTable, close}) => {
    const handleSubmit = () => {

        deleteBonusById(currentBonus.id)
            .then(() => {
                refreshTable()
                setUserMessage({message: "Bonus was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={"Delete Bonus"} isAgreePopup>
            <div className="delete-bonus-popup-body">
                <span className="t-s3">Do you want to delete this bonus</span>
                <SensitiveInfo>
                    <span className="t-s3">{currentBonus.amount} {currentBonus.currency.iso_code}</span>
                </SensitiveInfo>
                <span className="t-s3">{`(Reason: ${currentBonus.reason})?`}</span>
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteBonusPopup
