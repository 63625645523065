import {API_V1_URL} from "../../constants/other"
import http from "../../http"

const ROLES_URL = `${API_V1_URL}/roles/`


export const getRoles = (params) => http.get(ROLES_URL, {params})

export const createRole = (data) => http.post(ROLES_URL, {data})

export const deleteRole = (id) => http.delete(`${ROLES_URL}${id}`)

export const updateRoleById = (id, data) => http.put(`${ROLES_URL}${id}`, {data})

export const getPermissions = () => http.get(`${ROLES_URL}permissions`)
