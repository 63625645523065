import React from "react"

import {useDeleteClientMutation} from "../../../api/clients"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const DeleteClientPopup = ({client, setUserMessage, onSubmit, close}) => {

    const [deleteClient] = useDeleteClientMutation()

    const handleSubmit = () => {

        deleteClient(client.id)
            .unwrap()
            .then(() => {
                onSubmit()
                close()
                setUserMessage({message: "Client was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete "${client.name}"?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteClientPopup
