import React from "react"

import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"
import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import {formatDate} from "../../../utils/datetime.utils"
import {SensitiveInfo} from "../../../components/SensitiveInfo/SensitiveInfo"
import {DD_MM_YYYY} from "../../../constants/other"
import Newcoin from "../../../toolkits/Newcoin"


export const NewcoinsHistoryTable = ({newcoinsTransactions}) => {
    const newcoinsTransactionsList = newcoinsTransactions.map((newcoinsTransaction, i) => {
        return (
            <tr key={`${i} ${newcoinsTransaction.id}`} className="custom-scrollable-table-row">
                <td className="newcoins-history-content-table-first-column">
                    {newcoinsTransaction.pending && <span className="newcoins-history-pending t-b3">pending</span>}
                    <SensitiveInfo value={<span className="t-s1">
                        {newcoinsTransaction.amount > 0
                            ? <><span className={`${newcoinsTransaction.pending ? "" : "newcoins-history-received"}`}>+{newcoinsTransaction.amount}</span> <Newcoin type={newcoinsTransaction.pending ? "inactive" : "received"} /></>
                            : <><span className={`${newcoinsTransaction.pending ? "" : "newcoins-history-withdrawn"}`}>{newcoinsTransaction.amount}</span> <Newcoin type={newcoinsTransaction.pending ? "inactive" : "withdrawn"} /></>
                        }</span>}/>
                </td>
                <td>{`${formatDate(newcoinsTransaction?.as_of_date, DD_MM_YYYY)}`}</td>
                <td><div className="newcoins-history-content-table-last-column">{newcoinsTransaction?.reason}</div></td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className="newcoins-history-table">
                <thead>
                    <tr>
                        <th><span className="custom-scrollable-table-head-span-first">AMOUNT</span></th>
                        <th><span className="custom-scrollable-table-head-span">DATE</span></th>
                        <th><span className="custom-scrollable-table-head-span">REASON</span></th>
                    </tr>
                </thead>
                <tbody>
                    {newcoinsTransactions.length > 0 ? newcoinsTransactionsList : <div className="newcoins-history-table-empty-div"><EmptyResult/></div>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default NewcoinsHistoryTable