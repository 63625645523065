import "./index.scss"

import React, {useContext, useMemo} from "react"
import {EmptyResult} from "../../toolkits/EmptyResult/EmptyResult"
import {ThemeContext} from "../../styles/theme-context"
import DateTimeColumn from "./components/DateTimeColumn"
import InfiniteScroll from "react-infinite-scroll-component"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import EllipsisText from "./components/EllipsisText"


const Cell = ({ item, column, setUserMessage }) => {
    const value = column.get ? column.get(item) : item[column.field]

    if (column.type === "datetime") {
        return <DateTimeColumn date={value}/>
    }

    if (column.type === "copy") {
        return <EllipsisText value={value} copy={true} setUserMessage={setUserMessage}/>
    }

    return <EllipsisText value={value}/>
}

const Table = ({
    items,
    columns,
    loadNextPage,
    loading,
    hasMore,
    setUserMessage,
}) => {
    const theme = useContext(ThemeContext)

    const keyField = columns.find((column) => column.isKey)?.field
    if (!keyField) {
        console.warn("Key field is missing")
    }

    const body = useMemo(() => (
        items.length === 0 ? <EmptyResult/> :
            <tbody>
                {items.map((item) => (
                    <tr key={item[keyField]} className="custom-scrollable-table-row">
                        {columns.filter((column) => !column.hidden).map((column, i) => (
                            <td className={i === 0 ? "ns-table-first-column" : undefined} key={column.field}>
                                <Cell column={column} item={item} setUserMessage={setUserMessage}/>
                            </td>
                        ))}
                    </tr>))}
            </tbody>
    ), [items, setUserMessage])

    const header = useMemo(() => (
        <thead>
            <tr>
                {columns.filter((column) => !column.hidden).map((column) => (
                    <th key={column.field}>
                        <span className="custom-scrollable-table-head-span">{column.title}</span>
                    </th>
                ))}
            </tr>
        </thead>
    ), [columns])

    return (
        <InfiniteScroll
            pageStart={0}
            next={loadNextPage}
            hasMore={hasMore}
            loader={<CircularProgress key="circular-progress"/>}
            dataLength={items.length}
            useWindow={false}
        >
            {loading ? <StyledCircularProgress/> :
                <div className={`custom-scrollable-table ${theme.tableClassName}`}>
                    <table className="ns-table">
                        {header}
                        {body}
                    </table>
                </div>
            }
        </InfiniteScroll>
    )
}

export default Table