import {httpMethodRtk} from "../http"
import {API_V1_URL} from "../constants/other"
import {createApi} from "@reduxjs/toolkit/query/react"

export const DEFAULT_REFETCH_ON_MOUNT_INTERVAL = 100


export const baseQuery = ({method="get", path, params, data}) => httpMethodRtk(method)(`${API_V1_URL}/${path}`, {params, data})

export const coinsRelatedApi = createApi({
    reducerPath: "coinsRelatedApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: [
        "BalanceAvailable",
        "BalanceSpent",
    ],
    endpoints: () => ({}),
})


export const timeRelatedApi = createApi({
    reducerPath: "timeRelatedApi",
    baseQuery,
    refetchOnMountOrArgChange: DEFAULT_REFETCH_ON_MOUNT_INTERVAL,
    tagTypes: [
        "TimeRecords",
        "UserTimeRecords",
        "UserTimeRecordDetails",
        "UserTimeRecordGroupedReports",
        "UserTimeTrackerChart",
        "UserCalendarData",
        "UserReport",

        "TimeRecordTemplates",

        // Time offs related tags
        "TimeOffRequests",
        "UserTimeOffRequests",
        "TimeOffBalances",
        "TimeOffBalancesUniqueYears",
        "TimeOffsUsageInfo",
    ],
    endpoints: () => ({}),
})
