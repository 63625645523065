import React, { useState } from "react"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import { ERROR_CODE, SUCCESS_CODE } from "../../../../constants/other"
import SingleDatePicker from "../../../../toolkits/Calendar/SingleDatePicker"
import { setUserLastDate } from "../../api"

const SetUserLastDatePopup = ({
    close,
    day,
    userId,
    setUserMessage,
    receiveUserInfo,
}) => {
    const [lastDate, setLastDate] = useState(null)

    const handleSubmit = () => {
        const data = lastDate
            ? { last_date: lastDate.split(".").reverse().join("-") }
            : { last_date: null }

        setUserLastDate(userId, data)
            .then(() => {
                receiveUserInfo()
                close(false)
                setUserMessage({
                    message: "Last day was successfully changed!",
                    code: SUCCESS_CODE,
                })
            })
            .catch((error) => {
                setUserMessage({
                    message: error.response.data.message,
                    code: ERROR_CODE,
                })
            })
    }

    return (
        <CustomPopup text_title={"Set Last Day"} isAgreePopup>
            <SingleDatePicker
                label={"Last day"}
                className="single-datepicker-field"
                setNewDate={(value) => setLastDate(value)}
                defaultValue={day ? day : null}
                reset={true}
            />
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={() => close(false)}>
                        Cancel
                    </SmallButton>
                    <SmallButton onClick={handleSubmit}>{"Submit"}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default SetUserLastDatePopup