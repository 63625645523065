import {API_V1_URL} from "../../constants/other"
import http from "../../http"

const USERS_URL = `${API_V1_URL}/users/`
const SALARIES_URL = `${USERS_URL}salaries`
const OVERTIMES_URL = `${API_V1_URL}/overtime`
const BONUS_URL = `${API_V1_URL}/bonuses/`
const NEWCOINS_TRANSACTIONS_URL = `${API_V1_URL}/newcoins-transactions/`

export const getUserSalaries = (user_id, params) => http.get(`${USERS_URL}${user_id}/salaries`, {params})

export const createSalary = (data) => http.post(SALARIES_URL, {data})

export const deleteSalaryById = (salaryId) => http.delete(`${SALARIES_URL}/${salaryId}`)

export const updateUserSalary = (salary_id, payload) => http.put(`${USERS_URL}salaries/${salary_id}`, {data: payload})

export const createOvertime = (salaryId, data) => http.post(`${OVERTIMES_URL}/${salaryId}`, {data})

export const updateOvertimeById = (overtimeId, data) => http.put(`${OVERTIMES_URL}/${overtimeId}`, {data})

export const deleteOvertimeById = (overtimeId) => http.delete(`${OVERTIMES_URL}/${overtimeId}`)

export const getUserBonuses = (user_id, params) => http.get(`${USERS_URL}${user_id}/bonuses`, {params})

export const createBonus = (data) => http.post(BONUS_URL, {data})

export const deleteBonusById = (bonusId) => http.delete(`${BONUS_URL}${bonusId}`)

export const updateBonusById = (bonusId, data) => http.put(`${BONUS_URL}${bonusId}`, {data})

export const getNewcoinsTransactions = (params) => http.get(`${NEWCOINS_TRANSACTIONS_URL}`, {params})

export const createNewcoinsTransaction = (data) => http.post(NEWCOINS_TRANSACTIONS_URL, {data})

export const deleteNewcoinsTransactionById = (newcoinsTransactionId) => http.delete(`${NEWCOINS_TRANSACTIONS_URL}${newcoinsTransactionId}`)

export const updateNewcoinsTransactionById = (newcoinsTransactionId, data) => http.put(`${NEWCOINS_TRANSACTIONS_URL}${newcoinsTransactionId}`, {data})

export const updateUserNewcoinsSettings = (userId, data) => http.put(`${USERS_URL}${userId}/newcoins-settings`, {data})
