import React, {useState} from "react"
import {useSelector} from "react-redux"

import {createRole, updateRoleById} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import TextInput from "../../../toolkits/TextInput/TextInput"
import DynamicBoxList from "../../../toolkits/DynamicBoxList"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"


const CreateEditRolePopup = ({create, role, setUserMessage, refreshTable, close}) => {
    const [name, setName] = useState(role?.role_name)
    const [permissionsIds, setPermissionsIds] = useState(role?.permissions.map((x) => x.id) || [])

    const allPermissions = useSelector((state) => state.roles.permissions)


    const handleSubmit = () => {
        const data = {
            role_name: name,
            permission_ids: permissionsIds,
        }

        if (create) {
            createRole(data)
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: "Role was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        } else {
            updateRoleById(role.id, data)
                .then(() => {
                    refreshTable()
                    close()
                    setUserMessage({message: "Role was successfully updated", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup text_title={!create ? "Edit Role" : "Create Role"} className="roles-popup">
            <div className="popup-content">
                <TextInput
                    text_label="Name"
                    placeholder=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <DynamicBoxList
                    mainLabel="Permissions"
                    defaultValues={role?.permissions.map((x) => x.id) || []}
                    items={allPermissions}
                    onChange={setPermissionsIds}
                    searchFunc={(perm, searchKey) => perm.permission_name.includes(searchKey)}
                    getContent={perm => perm.permission_name}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!name || !permissionsIds.length}
                    > {!create ? "SAVE" : "CREATE"} </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateEditRolePopup
