import {combineReducers} from "redux"

import authReducer from "../pages/Auth/reducers/authReducer"
import employeesReducer from "../pages/Employees/reducers/employeesReducer"
import profileReducer from "../pages/Profile/reducers/profileReducer"
import performanceReviewsReducer from "../pages/PerformanceReviews/reducers/performanceReviewsReducer"
import equipmentReducer from "../pages/Equipment/reducers/equipmentReducer"
import settingsReducer from "../pages/Settings/reducers/settingsReducer"
import calendarDataReducer from "../pages/TimeTracker/reducers/calendarDataReducer"
import reportsPageReducer from "../pages/Reports/reducers/reportsPageReducer"
import equipmentCategoriesReducer from "../pages/EquipmentCategories/reducers/EquipmentCategoriesReducer"
import publicDayOffsReducer from "../pages/PublicDayOffs/reducers/publicDayOffsReducer"
import dashboardNotificationsReducer from "../pages/Notifications/reducers/dashboardNotificationsReducer"
import userNotificationsReducer from "../pages/Notifications/reducers/userNotificationsReducer"
import salaryHistoryReducer from "../pages/SalaryHistory/reducers/salaryHistoryReducer"
import rolesReducer from "../pages/Roles/reducers/rolesReducer"
import paymentReportsReducer from "../pages/PaymentReports/reducers/paymentReportsReducer"
import {bonusReducer} from "../pages/SalaryHistory/reducers/bonusHistoryReducer"
import navigationReducer from "../layouts/Navs/reducers/navigationReducer"
import BonusEarnReducer from "../pages/Reports/reducers/BonusEarnReducer"
import SalaryEarnReducer from "../pages/Reports/reducers/SalaryEarnReducer"
import OvertimeEarnReducer from "../pages/Reports/reducers/OvertimeEarnReducer"
import onboardingRequestsReducer from "../pages/OnboardingRequests/reducers/onboardingRequestsReducer"
import {newcoinsTransactionsReducer} from "../pages/SalaryHistory/reducers/newcoinsHistoryReducer"
import newcoinsBalancesReducer from "../pages/NewcoinsBalances/reducers/newcoinsBalancesReducer"
import productsReducer from "../pages/Products/reducers/productsReducer"
import {shopSlice} from "../pages/Shop/reducers/shopReducer"
import {tableDataSlice} from "../hooks/common/useTableData"
import {productsApi} from "../api/products"
import {equipmentApi} from "../api/equipment"
import {auditLogsApi} from "../api/auditLogs"
import {coinsRelatedApi, timeRelatedApi} from "../api/base"
import {projectsApi} from "../api/projects"
import {clientsApi} from "../api/clients"
import {employeePositionsApi} from "../api/employeePositions"
import createProductReducer from "../pages/CreateProduct/reducers/createProductReducer"
import purchaseRequestsReducer from "../pages/PurchaseRequests/reducers/purchaseRequestsReducer"
import viewProductReducer from "../pages/ViewProduct/reducers/viewProductReducer"
import personalPurchaseHistoryReducer from "../pages/PersonalPurchaseHistory/reducers/personalPurchaseHistoryReducer"
import couponsReducer from "../pages/Coupons/reducers/CouponsReducer"

/**
 * @desc Reducer function that invokes every reducer inside the passed object.
 * @const
 * @type {object}
 */
const rootReducer = combineReducers({
    auth: authReducer,
    employees: employeesReducer,
    onboardingRequests: onboardingRequestsReducer,
    equipment: equipmentReducer,
    profile: profileReducer,
    settings: settingsReducer,
    performanceReviews: performanceReviewsReducer,
    calendarData: calendarDataReducer,
    reportsPage: reportsPageReducer,
    equipmentCategories: equipmentCategoriesReducer,
    publicDayOffs: publicDayOffsReducer,
    dashboardNotifications: dashboardNotificationsReducer,
    userNotifications: userNotificationsReducer,
    salaryHistory: salaryHistoryReducer,
    roles: rolesReducer,
    coupons: couponsReducer,
    bonuses: bonusReducer,
    newcoinsTransactions: newcoinsTransactionsReducer,
    newcoinsBalances: newcoinsBalancesReducer,
    products: productsReducer,
    shop: shopSlice.reducer,
    tableData: tableDataSlice.reducer,
    [auditLogsApi.reducerPath]: auditLogsApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [employeePositionsApi.reducerPath]: employeePositionsApi.reducer,
    [coinsRelatedApi.reducerPath]: coinsRelatedApi.reducer,
    [timeRelatedApi.reducerPath]: timeRelatedApi.reducer,
    createProduct: createProductReducer,
    navigation: navigationReducer,
    paymentReports: paymentReportsReducer,
    earnInfo: SalaryEarnReducer,
    bonusInfo: BonusEarnReducer,
    overtimeInfo: OvertimeEarnReducer,
    purchaseRequests: purchaseRequestsReducer,
    personalPurchaseHistory: personalPurchaseHistoryReducer,
    viewProduct: viewProductReducer,

})

export default rootReducer
