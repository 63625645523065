import React, {useRef, useState} from "react"
import {useMediaQuery} from "react-responsive"
import DatePicker from "react-multi-date-picker"
import InputIcon from "react-multi-date-picker/components/input_icon"

import {CustomRangeDatePickerHeader} from "../../toolkits/Calendar/RangeDatePicker"

import {useCalendar} from "../../hooks"
import {DAYS_PER_WEEK, DD_MM_YYYY, MOBILE_WIDTH} from "../../constants/other"
import { CustomButton } from "../../toolkits/Calendar/CustomButton"
import useCurrentUserId from "../../hooks/useCurrentUserId"
import moment from "moment/moment"
import {endOfMonth, startOfMonth} from "date-fns"
import {formatDate} from "../../utils/datetime.utils"

const CustomDate = ({label, name, setCustom, setActive, active, ...props}) => {
    const userId = useCurrentUserId() // This doesn't really belong here but let's change it later
    const [calendarDateBoundaries, setCalendarDateBoundaries] = useState({
        startDate: formatDate(moment(startOfMonth(new Date)).add(-DAYS_PER_WEEK, "days")),
        endDate: formatDate(moment(endOfMonth(new Date)).add(-DAYS_PER_WEEK, "days")),
    })
    const {fillCalendar} = useCalendar({ userId, startDate: calendarDateBoundaries.startDate, endDate: calendarDateBoundaries.endDate })

    const calendarRef = useRef()
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const [rangeDates, setRangeDates] = useState([])

    const CustomRangeInput = ({openCalendar, value}) => {
        let from = value[0] || ""
        let to = value[1] || ""

        const customRange = name ? `${from}-${to}` : (from && to ? "from " + from + ", to " + to : from)

        return name ? (
            <span
                onClick={openCalendar}
                className={`reports-menu-item ${active === name ? "reports-menu-active t-s3" : "t-s4"}`}
            >
                {name}
            </span>
        ) :(
            <InputIcon
                onFocus={openCalendar}
                value={customRange}
                readOnly
            />
        )
    }

    return (
        <>
            {label && <p className="input-label">{label}</p>}
            <DatePicker
                mapDays={({date, today, selectedDate, isSameDate}) => {
                    return fillCalendar(date, today, selectedDate, isSameDate, new Date(), false, false, false, null, null, rangeDates)
                }}
                onChange={(selectedDates) => {selectedDates.length === 2 ? (
                    setCustom({
                        created_after: new Date(selectedDates[0]),
                        created_before: new Date(selectedDates[1])
                    }),
                    setRangeDates([...selectedDates]),
                    setActive(name)
                ) : setRangeDates([])}}
                range
                ref={calendarRef}
                render={<CustomRangeInput/>}
                inputClass="calendar"
                format={DD_MM_YYYY}
                renderButton={<CustomButton onCalendarBoundariesChange={(startDate, endDate) => setCalendarDateBoundaries({ startDate, endDate })}/>}
                showOtherDays
                onlyShowInRangeDates={true}
                calendarPosition="bottom"
                weekStartDayIndex={1}
                plugins={[
                    <CustomRangeDatePickerHeader
                        position={isMobile ? "bottom" : "right"}
                        calendarTitle="CUSTOM RANGE"
                        key="range_date_picker_header"
                    />
                ]}
                {...props}
            />
        </>
    )
}

export default CustomDate
