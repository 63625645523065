import React, {useEffect, useState} from "react"
import "./index.scss"
import {Redirect, useParams} from "react-router-dom"
import {receiveProduct} from "./actions/viewProductActions"
import {useDispatch, useSelector} from "react-redux"
import DefaultProductImage from "../../assets/images/Add_image.svg"
import {Helmet} from "react-helmet"
import Newcoin from "../../toolkits/Newcoin"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import {ERROR_CODE, DESKTOP_WIDTH, PRODUCT_STATUS, SUCCESS_CODE} from "../../constants/other"
import Counter from "../../toolkits/Counter"
import ProductOption from "./components/ProductOption"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import Popup from "reactjs-popup"
import BuyProductPopup from "./components/BuyProductPopup"
import {getColorOptionValues} from "../../utils/other.utils"
import {useMediaQuery} from "react-responsive"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import {EditProductRoute, ErrorRoute} from "../../routes"
import Protected from "../../components/Protected/Protected"
import {PERMISSIONS} from "../../constants/permissions"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import {useCreateOrderMutation} from "../../api/shop"
import useCoinsBalance from "../../hooks/useCoinsBalance"


const ViewProduct = () => {
    const [createPurchaseRequest] = useCreateOrderMutation()
    const {hasPermission} = useSelector((state) => state.profile)
    const {id} = useParams()
    const {location: {state}} = useCustomHistory()
    const history = useCustomHistory()
    const dispatch = useDispatch()
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const {product, loading} = useSelector(state => state.viewProduct)
    const {user} = useSelector(state => state.auth)

    const { balance } = useCoinsBalance({userId: user.uid})
    const [userMessage, setUserMessage] = useState(null)
    const [showBuyPopup, setShowBuyPopup] = useState(false)

    const [mainImage, setMainImage] = useState(DefaultProductImage)
    const [galleryImages, setGalleryImages] = useState([])
    const [amount, setAmount] = useState(1)
    const [isAvailable, setIsAvailable] = useState(true)
    const [selectedValues, setSelectedValues] = useState({})
    const [selectedProductVariant, setSelectedProductVariant] = useState(null)
    const isProductUnavailable = product.status === PRODUCT_STATUS.DELETED || !product.public
    const isUserNotAllowed = !hasPermission(PERMISSIONS.WRITE_SHOP)

    const handleBuy = (close) => {
        if (user && selectedProductVariant) {
            const data = {
                user_id: user.uid,
                product_variant_id: selectedProductVariant?.id,
                quantity: amount,
                total_price: amount * product.raw_price,
            }

            if (data.total_price > balance) {
                setUserMessage({message: "You have insufficient balance for this purchase.", code: ERROR_CODE})
                close()
                return
            }

            createPurchaseRequest(data)
                .unwrap()
                .then(() => {
                    setUserMessage({message: "Your purchase is submitted", code: SUCCESS_CODE})
                    close()
                })
                .catch(error => {
                    console.error("Error submitting the purchase", error)
                    setUserMessage({message: "Error submitting the purchase.", code: ERROR_CODE})
                    close()
                })
        }
    }

    const handleImageClick = (image) => {
        setMainImage({...image, is_main: true})
    }

    useEffect(() => {
        if (id) {
            dispatch(receiveProduct(id))
        }
    }, [id])

    useEffect(() => {
        if (product?.images) {
            const mainImg = product?.images.find((image) => image.is_main === true) || DefaultProductImage
            const otherImages = product?.images.filter((image) => image.is_main !== true)
            const sortedImages = otherImages.length > 0 ? [mainImg, ...otherImages] : []

            setMainImage(mainImg)
            setGalleryImages(sortedImages)
            setIsAvailable([PRODUCT_STATUS.IN_STOCK, PRODUCT_STATUS.PRE_ORDER].includes(product.status))
        }
    }, [product])


    useEffect(() => {
        if (product?.options) {
            const initialValues = product.options.reduce((acc, option) => {
                const name = getColorOptionValues(option.values[0]?.value).text || ""
                return { ...acc, [option.position]: name }
            }, {})
            setSelectedValues(initialValues)
        }
    }, [product?.options])

    useEffect(() => {
        if (product?.variants && selectedValues) {
            const matchingVariant = product.variants.find(variant => {
                return Object.keys(selectedValues).every((position) => {
                    const optionValue = getColorOptionValues(variant["option" + position]).text
                    return optionValue === selectedValues[position]
                })
            })
            setSelectedProductVariant(matchingVariant)
        }
    }, [selectedValues, product?.variants])

    return !loading && isProductUnavailable && isUserNotAllowed ?
        <Redirect to={{pathname: ErrorRoute.path, state: {errorInfo: "Not allowed"}}}/>
        :
        (
            <>
                <Helmet>
                    <title> Products - Newsoft Inside </title>
                </Helmet>
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                {loading ? <StyledCircularProgress style={{marginTop: "2rem"}}/>
                    :
                    <div className="view-product">
                        <div className="view-product-images">
                            <img src={mainImage.image_url} className="view-product-images-main" alt="Product Image"/>
                            <div className="view-product-images-overlay">
                                {product.labels.length > 0 &&
                                    <div className="view-product-images-overlay-labels">
                                        {product.labels.map((label, index) => (
                                            <span key={index} style={{backgroundColor: label.color}} className="view-product-images-overlay-labels-item t-b1">{label.name}</span>
                                        ))}
                                    </div>
                                }
                                {product.pre_order_delivery_time &&
                                    <div className="view-product-images-overlay-delivery-time t-b1">
                                        <LocalShippingOutlinedIcon /> {product.pre_order_delivery_time}
                                    </div>
                                }
                            </div>
                            {galleryImages.length > 0 &&
                            <div className="view-product-images-gallery">
                                {galleryImages.map((image, index) => (
                                    <span key={index} className="image-item">
                                        <img onClick={() => handleImageClick(image)} key={index}  src={image.image_url} alt="Product Image"/>
                                        {image.image_url === mainImage?.image_url && <span className="image-selected-rectangle"></span>}
                                    </span>
                                ))}
                            </div>}
                        </div>
                        <div className="view-product-content">
                            <div className="view-product-content-name t-h1">
                                {product.name}
                            </div>
                            {!isDesktop ?
                                <div className="view-product-content-price t-h1">
                                    <span className={`${isAvailable ? "active" : "inactive"}`}>{product.raw_price}</span> <Newcoin type={`${isAvailable ? "active" : "empty"}`} />
                                </div>
                                :
                                product?.description && <div className="view-product-content-description">
                                    {product.description}
                                </div>
                            }
                            <div className="view-product-content-status">
                                <div className="t-button">Status:</div>
                                <span className={`${isAvailable ? "available" : "unavailable"}`}>{product.status}</span>
                            </div>
                            {product?.attributes?.length > 0 &&
                                <div className="view-product-content-attributes">
                                    {product.attributes.map((attribute, index) => (
                                        <div key={index} className="view-product-content-attributes-item">
                                            <div className="view-product-content-attributes-item-name t-button">{attribute.name}</div>
                                            <div className="view-product-content-attributes-item-value">{attribute.value}</div>
                                        </div>
                                    ))}
                                </div>
                            }
                            {product?.options?.length > 0 &&
                                <div className="view-product-content-options">
                                    {product.options.map((option, index) => (
                                        <ProductOption key={index} option={option} selectedValues={selectedValues} setSelectedValues={setSelectedValues}/>
                                    ))}
                                </div>
                            }
                            {!isDesktop ?
                                product?.description && <div className="t-b2 view-product-content-description">
                                    {product.description}
                                </div>
                                :
                                <div className="view-product-content-price t-h1">
                                    <span className={`${isAvailable ? "active" : "inactive"}`}>{product.raw_price}</span> <Newcoin type={`${isAvailable ? "active" : "empty"}`} />
                                </div>}
                            <div className="view-product-content-buttons">
                                {!state?.edit && product.public ?
                                    product.status !== PRODUCT_STATUS.OUT_OF_STOCK &&
                                    <Protected permissions={[PERMISSIONS.WRITE_OWN_PURCHASE_REQUEST]}>
                                        <Counter count={amount} setCount={setAmount} />
                                        <SmallButton btnType={"primary"} onClick={() => setShowBuyPopup(true)}>Buy</SmallButton>
                                    </Protected>
                                    :
                                    product.status !== PRODUCT_STATUS.DELETED && isDesktop &&
                                    <Protected permissions={[PERMISSIONS.WRITE_SHOP]}>
                                        <span className="view-product-content-buttons-edit t-button-underline" onClick={() => history.push(`${EditProductRoute.path.replace(":id", product.id)}`, {id: product.id, title: EditProductRoute.name})}>edit</span>
                                    </Protected>
                                }
                            </div>
                        </div>
                        <Popup open={showBuyPopup} onClose={() => setShowBuyPopup(false)} closeOnDocumentClick={false} modal>
                            {close => (
                                <BuyProductPopup
                                    product={product}
                                    amount={amount}
                                    selectedProductVariant={selectedProductVariant}
                                    handleBuy={handleBuy}
                                    close={close}
                                />
                            )}
                        </Popup>
                    </div>
                }
            </>
        )
}

export default ViewProduct