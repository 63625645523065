import React, {useEffect, useRef, useState} from "react"
import DatePicker from "react-multi-date-picker"
import InputIcon from "react-multi-date-picker/components/input_icon"

import {useCalendar} from "../../hooks"
import {CustomButton} from "./CustomButton"
import {formatDate, validateDate} from "../../utils/datetime.utils"
import {DAYS_PER_WEEK, DD_MM_YYYY, MAX_DAYS_PER_MONTH, MAX_YEAR, MONTHS_IN_YEAR} from "../../constants/other"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import useCurrentUserId from "../../hooks/useCurrentUserId"
import moment from "moment/moment"
import {endOfMonth, startOfMonth} from "date-fns"


const DateInputIcon = ({openCalendar, value, handleValueChange, reset, ...props}) => {
    const [valid, setValid] = useState(true)
    const {datepickerRef, className, defaultValue} = props
    if(!value || value === ""){
        if (!reset){
            value = formatDate(defaultValue, DD_MM_YYYY)
            value = value === "Invalid date" ? null : value
        }
    }
    if(Array.isArray(value)){
        value = value[0]
    }

    const onChange = (e) => {
        const [day, month, year] = e.target.value.split(".").map((token) => token ? parseInt(token) : null)
        if ((day && day > MAX_DAYS_PER_MONTH) || (month && month > MONTHS_IN_YEAR) || (year && year > MAX_YEAR)) {
            return false
        }
        handleValueChange(e)
        setValid(validateDate(e.target.value))
    }

    const handleResetClick = (event) => {
        event.preventDefault()
        handleValueChange({ target: {value: ""} })
    }

    useEffect(() => {
        if(reset && defaultValue) {
            value = formatDate(defaultValue, DD_MM_YYYY)
            handleValueChange({ target: {value: value} })
        }
    }, [])

    useEffect(() => {
        setValid(value ? validateDate(value) : true)
    }, [value])

    return (
        <label className="single-date-input-wrapper-label">
            <InputIcon
                placeholder={DD_MM_YYYY}
                className={className || " "}
                onClick={() => datepickerRef.current?.isOpen ? datepickerRef.current.closeCalendar() : openCalendar()}
                value={value}
                onChange={onChange}
            />
            {value && reset && valid && <CancelRoundedIcon onClick={handleResetClick} className="reset-input-button"/>}
            {!valid && <span className="t-s3 validation-error">*Enter valid date</span>}
        </label>
    )
}

const SingleDatePicker = ({
    label,
    defaultValue,
    setNewDate,
    className = "",
    withPublicDayOffs,
    value,
    minDate,
    maxDate,
    disableDays,
    reset,
    onCalendarBoundariesChange = null,
    ...props
}) => {
    const userId = useCurrentUserId() // This doesn't really belong here but let's change it later
    const [calendarDateBoundaries, setCalendarDateBoundaries] = useState({
        startDate: formatDate(moment(startOfMonth(new Date)).add(-DAYS_PER_WEEK, "days")),
        endDate: formatDate(moment(endOfMonth(new Date)).add(-DAYS_PER_WEEK, "days")),
    })
    const {fillCalendar} = useCalendar({ userId, startDate: calendarDateBoundaries.startDate, endDate: calendarDateBoundaries.endDate })
    useEffect(() => onCalendarBoundariesChange?.(calendarDateBoundaries), [calendarDateBoundaries])

    const [selectedDate, setSelectedDate] = useState(defaultValue)
    const ref = useRef()

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            ref.current.closeCalendar()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const onSelectedDateChanged = (date) => {
        if (date) {
            setSelectedDate(date.toDate())
            setNewDate(date.toString())
        } else {
            setSelectedDate(null)
            setNewDate(null)
        }
    }

    return (
        <>
            {label && <p className="single-date-input-label t-s3">{label}</p>}
            <div className="single-date-input-wrapper">
                <DatePicker
                    ref={ref}
                    mapDays={({date, today, selectedDate, isSameDate}) => {
                        return fillCalendar(date, today, selectedDate, isSameDate, new Date(), disableDays, props?.showTimeOffsAndLoggedDays, withPublicDayOffs)
                    }}
                    render={<DateInputIcon className={className} defaultValue={defaultValue} datepickerRef={ref} reset={reset}/>}
                    inputClass="calendar"
                    format={DD_MM_YYYY}
                    value={value ? value : formatDate(selectedDate, DD_MM_YYYY)}
                    onChange={(date) => onSelectedDateChanged(date)}
                    renderButton={<CustomButton onCalendarBoundariesChange={(startDate, endDate) => setCalendarDateBoundaries({startDate, endDate})}/>}
                    calendarPosition="bottom"
                    weekStartDayIndex={1}
                    minDate={minDate}
                    maxDate={maxDate}
                    {...props}
                />
            </div>
        </>
    )
}

export default SingleDatePicker