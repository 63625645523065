import React, { useState } from "react"
import {STATUS_FILTERS} from "../../../constants/other"

const TimeOffStatusFilter = ({ setStatusFilter }) => {
    const [selectedStatus, setSelectedStatus] = useState(STATUS_FILTERS.ALL)

    const handleStatusClick = (status) => {
        setSelectedStatus(status)
        setStatusFilter(status)
    }

    return (
        <div className="time-off-status-filter">
            <span className="time-off-status-filter-span t-s4">Status:</span>
            {Object.values(STATUS_FILTERS).map((status) => (
                <button
                    key={status}
                    type="button"
                    onClick={() => handleStatusClick(status)}
                    className={`time-off-status-button ${selectedStatus === status ? "time-off-status-button-selected t-s3" : "t-s4"}`}
                >
                    {status}
                </button>
            ))}
        </div>
    )
}

export default TimeOffStatusFilter
