import {useMemo} from "react"
import {useGetTimeRecordsQuery, useGetUserTimeRecordsQuery} from "../api/timeRecords"


const useTimeRecords = ({ userId, ...params }, { skip } = {}) => {
    const {data: globalTimeData, isLoading: globalTimeRecordsLoading, refetch: globalRefetch} = useGetTimeRecordsQuery(params, { skip: skip || userId })
    const {data: userTimeData, isLoading: userTimeRecordsLoading, refetch: userRefetch} = useGetUserTimeRecordsQuery({userId, params}, { skip: skip || !userId })

    const result = useMemo(() => {
        const isLoading = userId ? userTimeRecordsLoading : globalTimeRecordsLoading
        const data = userId ? userTimeData : globalTimeData
        const total = userId ? userTimeData?.total : globalTimeData?.total
        const timeRecords = (userId ? userTimeData?.items : globalTimeData?.items) || []
        const refetch = userId ? userRefetch : globalRefetch

        return {
            data,
            timeRecords,
            total,
            isLoading,
            refetch
        }

    }, [userId, globalTimeData, userTimeData, globalTimeRecordsLoading, userTimeRecordsLoading])

    return result
}

export default useTimeRecords