import React, {useState} from "react"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"

import {generateCancelToken} from "../../../http"

import {useCustomHistory} from "../../../hooks/useCustomHistory"

import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"

import UserImage from "../../../assets/images/user_photo.png"

import Protected from "../../../components/Protected/Protected"
import DeleteTimeRecordPopup from "./DeleteTimeRecordPopup"
import EditTimeRecordPopup from "../../TimeTracker/components/EditTimeRecordPopup"
import InfoPopup from "../../../components/InfoPopup"

import {EmptyResult} from "../../../toolkits/EmptyResult/EmptyResult"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"

import {formatDate, minutesToString} from "../../../utils/datetime.utils"
import {canEditTimeRecord} from "../../../utils/permission.utils"

import {
    DD_MM_YYYY,
    ERROR_CODE,
    HOURS_TYPE_ONLY_TIME_OFF_OPTIONS,
    SUCCESS_CODE,
    TIME_RECORD_STATUS,
} from "../../../constants/other"
import {PERMISSIONS} from "../../../constants/permissions"
import {useUpdateTimeRecordMutation} from "../../../api/timeRecords"


const TimeRecordsTable = ({
    userId,
    timeRecords,
    isMainPage,
    setApprovedRecords,
    refreshTable,
    setUserMessage,
}) => {
    const [updateTimeRecord] = useUpdateTimeRecordMutation()
    const history = useCustomHistory()
    
    const dispatch = useDispatch()
    
    const [cancelToken, setCancelToken] = useState(null)

    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)
    
    const isMyProfile = auth.user.uid === userId

    const timeOffHoursTypes = HOURS_TYPE_ONLY_TIME_OFF_OPTIONS.map(option => option.value)
    
    const profileClick = (id) => history.push(`/profile/${id}`)

    const approveTimeRecord = (timeRecordId, isApprove, event) => {
        event.target.disabled = true
        const data = {
            status: isApprove === true ? TIME_RECORD_STATUS.APPROVED : TIME_RECORD_STATUS.DECLINED
        }
        
        updateTimeRecord({id: timeRecordId, data })
            .unwrap()
            .then(() => {
                setApprovedRecords(state => [...state, timeRecordId])
                setUserMessage({
                    message: `Your time record was ${isApprove ? "approved" : "declined"}!`,
                    code: SUCCESS_CODE,
                })
                dispatch(
                    receiveNotifications(generateCancelToken(cancelToken, setCancelToken))
                )
            })
            .catch(error => {
                setUserMessage({message: error.message || "An unexpected error occurred", code: ERROR_CODE})
                setApprovedRecords(state => state.filter(id => id !== timeRecordId))
            }).finally(() => {
                event.target.disabled = false
            })
    }

    const timeRecordsList = timeRecords.map((tr, i) => {
        const approveActions = (
            <Protected permissions={[PERMISSIONS.WRITE_TIME_RECORD]}>
                <button
                    className="time-records-content-actions custom-scrollable-table-link"
                    onClick={(event) => approveTimeRecord(tr.id, false, event)}
                >
                    {"decline"}
                </button>

                <button
                    className="time-records-content-actions custom-scrollable-table-link"
                    onClick={(event) => approveTimeRecord(tr.id, true, event)}
                >
                    {"approve"}
                </button>
            </Protected>
        )

        const standardActions = (
            <>
                {!timeOffHoursTypes.includes(tr.hours_type) && tr?.status !== TIME_RECORD_STATUS.IN_REVIEW &&
                    <Popup trigger={<span className="custom-scrollable-table-link">edit</span>} modal>
                        {close => (
                            <EditTimeRecordPopup
                                timeRecord={tr}
                                refreshData={refreshTable}
                                setUserMessage={setUserMessage}
                                close={close}
                                userId={userId}
                            />
                        )}
                    </Popup>
                }
                {(hasPermission(PERMISSIONS.WRITE_TIME_RECORD) ||
                        (hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) && isMyProfile && tr?.status === TIME_RECORD_STATUS.IN_REVIEW) ||
                        !timeOffHoursTypes.includes(tr.hours_type)) &&
                    <Popup trigger={<span className="custom-scrollable-table-link">delete</span>} modal>
                        {close => (
                            <DeleteTimeRecordPopup
                                timeRecord={tr}
                                refreshData={refreshTable}
                                setUserMessage={setUserMessage}
                                close={close}
                            />
                        )}
                    </Popup>
                }
            </>
        )

        return (
            <tr key={`${i} ${tr.id}`} className={`custom-scrollable-table-row${isMainPage ? "-user" : ""}`}>
                {isMainPage &&
                    <td>
                        <div className="custom-scrollable-table-employee">
                            <span className="custom-scrollable-table-employee-image clickable" onClick={() => profileClick(tr.user.id)}>
                                <img src={tr.user?.image_url ? tr.user.image_url : UserImage} alt="employee"/>
                            </span>
                            <span className="custom-scrollable-table-employee-name clickable" onClick={() => profileClick(tr.user.id)}>
                                {tr.user?.first_name} {tr.user?.last_name}
                            </span>
                        </div>
                    </td>
                }
                <td> {formatDate(tr.date, DD_MM_YYYY)} </td>
                <td> {tr.hours_type} </td>
                <td> {tr.project?.name} </td>
                <td> {minutesToString(tr.duration)} </td>
                <td>
                    <Popup trigger={<span className="cell-limited-text clickable"> {tr.note} </span>} modal>
                        {close => (
                            <InfoPopup
                                title="Note"
                                value={tr.note}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                {!isMainPage &&
                    <td>
                        <span
                            className={`time-records-content-status-${tr.status.split(" ").join("-").toLowerCase()} t-h3`}>
                            {tr.status}
                        </span>
                    </td>
                }
                <td>
                    {tr?.status === TIME_RECORD_STATUS.IN_REVIEW
                        ? (isMyProfile && hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD))
                            ? standardActions
                            : approveActions
                        : isMyProfile
                            ? (hasPermission(PERMISSIONS.WRITE_TIME_RECORD) ||
                                (hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) && canEditTimeRecord(tr.date)))
                                ? standardActions
                                : <></>
                            : hasPermission(PERMISSIONS.WRITE_TIME_RECORD)
                                ? standardActions
                                : <></>
                    }
                </td>
            </tr>
        )
    })

    return (
        <CustomScrollableTable>
            <table className={`time-records-content-table${isMainPage ? "-ext" : ""}`}>
                <thead>
                    <tr>
                        {isMainPage &&
                        <th><span className="custom-scrollable-table-head-span"> EMPLOYEE </span></th>
                        }
                        <th><span className="custom-scrollable-table-head-span"> DATE </span></th>
                        <th><span className="custom-scrollable-table-head-span"> HOURS TYPE </span></th>
                        <th><span className="custom-scrollable-table-head-span"> PROJECT </span></th>
                        <th><span className="custom-scrollable-table-head-span"> DURATION </span></th>
                        <th><span className="custom-scrollable-table-head-span"> NOTE </span></th>
                        {!isMainPage &&
                        <th><span className="custom-scrollable-table-head-span"> STATUS </span></th>
                        }
                        <th><span className="custom-scrollable-table-head-span"> ACTIONS </span></th>
                    </tr>
                </thead>
                <tbody>
                    {timeRecords.length > 0 ? timeRecordsList : <EmptyResult/>}
                </tbody>
            </table>
        </CustomScrollableTable>
    )
}

export default TimeRecordsTable
