import React from "react"

import {deleteOvertimeById} from "../api"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"


const DeleteOvertimePopup = ({username, overtime, setUserMessage, refreshTable, close}) => {
    const handleSubmit = () => {

        deleteOvertimeById(overtime.id)
            .then(() => {
                refreshTable()
                setUserMessage({message: "Overtime was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete ${username}'s overtime with coefficient ${overtime.coefficient}?`}
            isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteOvertimePopup
