import React, {useState} from "react"
import {useSelector} from "react-redux"

import {createUser} from "../api"

import EmployeePositionsDDList from "../../EmployeePositions/components/EmployeePositionsDDList"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import DDList from "../../../toolkits/DDList/DDList"
import TextInput from "../../../toolkits/TextInput/TextInput"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import SingleDatePicker from "../../../toolkits/Calendar/SingleDatePicker"
import SliderInput from "../../../toolkits/SliderInput"
import {getDraft, saveDraft} from "../../../utils/localstorage.utils"
import EyeImage from "@mui/icons-material/RemoveRedEyeRounded"
import ClosedEyeImage from "@mui/icons-material/VisibilityOffRounded"

import {
    EMPLOYMENT_TYPES,
    ERROR_CODE,
    SUCCESS_CODE,
    INFO_ALERT,
} from "../../../constants/other"


const formatedDate = (date) => {
    return date?.split(".").reverse().join("-")
}

const AddEmployeePopup = ({employee, setShowDraftCreateUser, refreshTable, setUserMessage, close}) => {
    const auth = useSelector((state) => state.auth)
    
    const [firstName, setFirstName] = useState(employee?.firstName)
    const [lastName, setLastName] = useState(employee?.lastName)
    const [employmentType, setEmploymentType] = useState(employee?.employmentType ?? EMPLOYMENT_TYPES[0])
    const [birthdate, setBirthdate] = useState(employee?.birthdate ?? null)
    const [firstDay, setFirstDay] = useState(employee?.hiring_date ?? null)
    const [phone, setPhone] = useState(employee?.phone)
    const [email, setEmail] = useState(employee?.email)
    const [position, setPosition] = useState(employee?.position)
    const [password, setPassword] = useState(null)
    const [showPasswordField, setShowPasswordField] = useState(false)
    const [visible, setVisible] = useState(true)
    const [onboarding, setOnboarding] = useState(false)

    const [passwordShown, setPasswordShown] = useState(false)

    const handleSubmit = () => {
        const data = {
            first_name: firstName,
            last_name: lastName,
            employment_type: employmentType,
            phone_number: phone,
            email: email,
            hiring_date: formatedDate(firstDay),
            birthdate: formatedDate(birthdate),
            job_position_id: position?.value || null,
            password: password,
            hidden: !visible,
            should_send_mail: true,
            onboarding_required: onboarding,
        }

        createUser(data)
            .then(() => {
                refreshTable()
                close()
                setUserMessage({message: "User was successfully created!", code: SUCCESS_CODE})
                saveDraft({createUser: {}})
                setShowDraftCreateUser(false)
            })
            .catch(error => {
                setUserMessage({
                    message: error?.response?.data.message || "Unexpected error occurred while creating user",
                    code: ERROR_CODE,
                })
            })
    }

    const isDisabledSubmit = () => {
        const fields = [
            firstName, lastName, phone, email, firstDay, birthdate
        ]
        return fields.some((field) => !field)
    }

    const getFormFields = () => {
        return ({
            ...(firstName ? {firstName} : {}),
            ...(lastName ? {lastName} : {}),
            ...(employmentType !== EMPLOYMENT_TYPES[0] ? {employmentType} : {}),
            ...(position ? {position} : {}),
            ...(firstDay ? {hiring_date: formatedDate(firstDay)} : {}),
            ...(birthdate ? {birthdate: formatedDate(birthdate)} : {}),
            ...(phone ? {phone} : {}),
            ...(email ? {email} : {}),
            ...(visible ? {visible} : {}),
            ...(onboarding ? {onboarding} : {})
        })
    }

    const saveDraftUser = () => {
        const draftCreateUser = getFormFields()
        const oldDraft = getDraft(auth.user.uid)?.createUser
            ? getDraft(auth.user.uid).createUser
            : {}
        saveDraft(auth.user.uid, {
            createUser: {...oldDraft, ...draftCreateUser}
        })
        if (Object.values(draftCreateUser).length) {
            setShowDraftCreateUser(true)
            setUserMessage({message: "User draft was created.", code: SUCCESS_CODE, type: INFO_ALERT})
        }
        close()
    }

    return (
        <CustomPopup
            className="add-employee-popup"
            text_title="Add Employee"
        >
            <div className="add-employee-popup-content">
                <div className="email-div">
                    <TextInput
                        text_label="Email"
                        type="email"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span onClick={() => setShowPasswordField(!showPasswordField)} className="show-password t-b4">add specific password</span>
                </div>
                {showPasswordField &&
                    <div className="password-div">
                        <TextInput
                            text_label="Password"
                            type={passwordShown ? "text" : "password"}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Optional"
                        />
                        {passwordShown ?
                            <EyeImage onClick={() => setPasswordShown(!passwordShown)} className="password-div-content-password-visible" alt="visible image"/>
                            : <ClosedEyeImage onClick={() => setPasswordShown(!passwordShown)} className="password-div-content-password-visible" alt="invisible image"/>}
                    </div>
                }
                <TextInput
                    text_label="First name"
                    type="text"
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextInput
                    text_label="Last name"
                    type="text"
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <DDList
                    text_label="Employment Type"
                    placeholder=""
                    defaultValue={{value: employmentType, label: employmentType}}
                    listOptions={EMPLOYMENT_TYPES.map((tp) => ({value: tp, label: tp}))}
                    onChange={tp => setEmploymentType(tp.value)}
                />
                <EmployeePositionsDDList
                    value={position}
                    setPosition={setPosition}
                    setUserMessage={setUserMessage}
                />
                <TextInput
                    text_label="Phone"
                    placeholder="+38.."
                    type="tel"
                    defaultValue={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <SingleDatePicker
                    label="First day"
                    defaultValue={firstDay}
                    className="single-datepicker-field"
                    setNewDate={(value) => setFirstDay(value)}
                />
                <SingleDatePicker
                    label="Birth date"
                    defaultValue={birthdate}
                    className="single-datepicker-field"
                    setNewDate={(value) => setBirthdate(value)}
                />
                <SliderInput
                    text_label="Onboarding"
                    active={onboarding}
                    setActive={setOnboarding}
                />
                <SliderInput
                    text_label="Publicly Visible"
                    active={visible}
                    setActive={setVisible}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={saveDraftUser}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={isDisabledSubmit()}> CREATE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default AddEmployeePopup
