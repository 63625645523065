import React from "react"


import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import {useDeleteProjectMutation} from "../../../api/projects"


const DeleteProjectPopup = ({project, onSubmit, setUserMessage, close}) => {
    const [deleteProject] = useDeleteProjectMutation()

    const handleSubmit = () => {
        deleteProject(project.id)
            .unwrap()
            .then(() => {
                onSubmit()
                close()
                setUserMessage({message: "Project was successfully deleted!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete "${project.name}" ?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteProjectPopup
