import React, {useState} from "react"

import {createOvertime, updateOvertimeById} from "../api"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import DDList from "../../../toolkits/DDList/DDList"
import SingleDatePicker from "../../../toolkits/Calendar/SingleDatePicker"

import {range} from "../../../utils/other.utils"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"


const CreateUpdateOvertimePopup = ({
    overtime,
    salaryId,
    minDate,
    maxDate,
    setUserMessage,
    refreshTable,
    close,
}) => {
    const [startDate, setStartDate] = useState(overtime ? overtime?.start_date : null)
    const [endDate, setEndDate] = useState(overtime ? overtime?.end_date : null)
    const [overtimeCoeff, setOvertimeCoeff] = useState(overtime ? {
        value: overtime?.coefficient, label: `x${overtime?.coefficient}`
    } : {})

    const overtimeCoefficientVariants = range(0, 3, 0.5)

    const isDisabledSubmit = () => {
        const formatedStartDate = startDate?.split(".").reverse().join("-")
        const formatedEndDate = endDate?.split(".").reverse().join("-")

        return ((overtime?.start_date === formatedStartDate
            && overtime?.end_date === formatedEndDate
            && overtime?.coefficient === overtimeCoeff.value))
            || !startDate || !overtimeCoeff
    }

    const handleSubmit = () => {
        const formatedStartDate = startDate.split(".").reverse().join("-")
        const formatedEndDate = endDate?.split(".").reverse().join("-")

        const data = {
            coefficient: overtimeCoeff.value,
            start_date: formatedStartDate,
            end_date: formatedEndDate,
        }

        if(!overtime){
            createOvertime(salaryId, data)
                .then(() => {
                    refreshTable()
                    close(false)
                    setUserMessage({message: "Overtime was successfully created!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        } else {
            updateOvertimeById(overtime.id, {...data, salary_id: salaryId})
                .then(() => {
                    refreshTable()
                    close(false)
                    setUserMessage({message: "Overtime was successfully changed!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup
            text_title={!overtime ? "Create Overtime coeff" : "Edit Overtime coeff"}
            className="update-salary-popup"
        >
            <DDList
                text_label="Value"
                placeholder=""
                defaultValue={overtimeCoeff}
                listOptions={overtimeCoefficientVariants.map((coeff) => ({value: coeff, label: `x${coeff}`}))}
                onChange={setOvertimeCoeff}
            />

            <SingleDatePicker
                label="Start date"
                className="single-datepicker-field"
                defaultValue={startDate ? startDate : null}
                setNewDate={setStartDate}
                minDate={minDate}
                maxDate={maxDate}
            />

            <SingleDatePicker
                label="End date"
                className="single-datepicker-field"
                defaultValue={endDate ? endDate : null}
                setNewDate={setEndDate}
                minDate={minDate}
                maxDate={maxDate}
            />

            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={() => close(false)}>CANCEL</SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit}
                        disabled={isDisabledSubmit()}>{overtime ? "SAVE" : "CREATE"}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateUpdateOvertimePopup
