import React, {useEffect, useState, useRef, useContext} from "react"
import {useDispatch, useSelector} from "react-redux"

import {createPerformanceReview} from "../api"
import {receiveEmployees} from "../../Employees/actions/employeesActions"
import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import DDList from "../../../toolkits/DDList/DDList"
import SingleDatePicker from "../../../toolkits/Calendar/SingleDatePicker"

import {SUCCESS_CODE, ERROR_CODE, PERFORMANCE_REVIEW_DATE_CHOICE, MOBILE_WIDTH} from "../../../constants/other"
import {nMonthsLater, formatDate} from "../../../utils/datetime.utils"
import RichTextEditor from "../../../toolkits/RichTextEditor"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"
import { useMediaQuery } from "react-responsive"
import { ThemeContext } from "../../../styles/theme-context"


const CreatePerformanceReviewPopup = ({refreshTable, setUserMessage, close}) => {
    const dispatch = useDispatch()

    const [userId, setUserId] = useState(null)
    const [date, setDate] = useState(null)
    const [improvements, setImprovements] = useState("")
    const [listOptions, setListOptions] = useState([])
    const [selectedBtn, setSelectedBtn] = useState(0)
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const theme = useContext(ThemeContext)

    const buttonBlock = useRef()

    const employees = useSelector((state) => state.employees.employees)

    const handleSubmit = () => {
        let formatedDate
        try {
            formatedDate = date?.split(".").reverse().join("-")
        } catch (err) {
            formatedDate = formatDate(date)
        }
        
        const data = {
            user_id: userId,
            date: formatedDate,
            improvements: improvements,
        }

        createPerformanceReview(data)
            .then(() => {
                refreshTable()
                dispatch(receiveNotifications())
                close()
                setUserMessage({message: "Performance review was successfully created!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }
 
    useEffect(() => {
        dispatch(receiveEmployees())
    }, [])

    useEffect(() => {
        setListOptions(
            employees && employees.map((employee) => ({
                value: employee.id,
                label: `${employee.first_name} ${employee.last_name}`
            }))
        )
    }, [employees])

    useEffect(() => {
        buttonBlock.current.scrollIntoView({ behavior: "smooth" })
    }, [improvements])
    
    return (
        <FlexiblePopup noStyle={true}>
            <div className="modify-performance-review-popup">
                <div className="modify-performance-review-popup-title">
                    <span className={`${isMobile ? "t-s1" : "t-h2"}`}>Create Performance Review</span>
                </div>
                <div className="modify-performance-review-popup-content create">
                    <DDList
                        text_label="Employee"
                        placeholder="Choose an employee"
                        listOptions={listOptions}
                        onChange={employee => setUserId(employee.value)}
                    />

                    <div className="performance-review-popup-content-date">
                        <label className="performance-review-popup-content-date-label t-s3">Date</label>
                        <div className="performance-review-popup-content-date-chips"> 
                            {PERFORMANCE_REVIEW_DATE_CHOICE.map((op, i) => (
                                <><button key={op.value} className={`${selectedBtn == op.value ? "performance-review-popup-content-date-chips-selected t-s3" : `t-s4 ${theme.chipsClassName}`}`}
                                    value={op.value} onClick={() => {setDate(nMonthsLater(op.value, new Date())), setSelectedBtn(op.value)}}>
                                    {op.label}
                                </button> 
                                {i < (PERFORMANCE_REVIEW_DATE_CHOICE.length-1) && <hr/>}
                                </>))
                            }
                        </div>   
                    </div>

                    <div onClick={() => setSelectedBtn(0)}>
                        <SingleDatePicker
                            className="single-datepicker-field"
                            defaultValue={date}
                            setNewDate={setDate}
                            value={date}
                        />
                    </div>

                    <RichTextEditor
                        title="Goals"
                        label="Write up goals of the review here..."
                        setValue={setImprovements}
                    />

                    <div className="popup-bottom" ref={buttonBlock}>
                        <div className="popup-bottom-button-container">
                            <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                            <SmallButton type="submit" onClick={handleSubmit} disabled={!date || !userId}> CREATE </SmallButton>
                        </div>
                    </div>
                </div>
            </div>
        </FlexiblePopup>
    )
}

export default CreatePerformanceReviewPopup
