import React from "react"
import {useSelector} from "react-redux"

import UserImage from "../../../assets/images/user_photo.png"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import TelegramButton from "../../../toolkits/TelegramButton"
import CopyButton from "../../../components/Miscellaneous/CopyButton"

import {formatDate} from "../../../utils/datetime.utils"


import {PERMISSIONS} from "../../../constants/permissions"
import {DD_MM_YYYY} from "../../../constants/other"


const EmployeeInfoPopup = ({employee, close, setUserMessage}) => {
    const {hasPermission} = useSelector((state) => state.profile)


    return (
        <CustomPopup className="employee-info-popup">
            <div className="employee-info-popup-header">
                <img src={employee.image_url ? employee.image_url : UserImage} alt="user"/> 
                <div className="employee-info-popup-header-name t-h2">{employee.first_name} {employee.last_name}</div>
                <div className="employee-info-popup-header-job-position t-s4">{employee.job_position?.name}</div>
            </div>
            <div className="employee-info-popup-content">
                <div className="employee-info-popup-content-row">
                    <div className="employee-info-popup-content-label">BIRTH DATE</div>
                    <div className="employee-info-popup-content-data">
                        <span className="aligned-tabs">
                            {formatDate(employee.birthdate, !hasPermission(PERMISSIONS.WRITE_USER) ? "DD MMM" : DD_MM_YYYY)}
                        </span>
                    </div>
                </div>
                <div className="employee-info-popup-content-row">
                    <div className="employee-info-popup-content-label">
                        <span>PHONE</span>
                        <TelegramButton phone_number={employee.phone_number}/>
                    </div>
                    <div className="employee-info-popup-content-data">
                        <span className="aligned-tabs">{employee.phone_number}</span>
                    </div>
                </div>
                <div className="employee-info-popup-content-row">
                    <div className="employee-info-popup-content-label">
                        <span>EMAIL</span>
                        <CopyButton value={employee.email} type="Email" setUserMessage={setUserMessage}/>
                    </div>
                    <CopyButton value={employee.email} type="Email" setUserMessage={setUserMessage}>
                        <div className="employee-info-popup-content-data clickable">
                            {employee.email}
                        </div>
                    </CopyButton>   
                </div>
            </div>

            <ClosePopupIcon onClick={close}/>
        </CustomPopup>
    )
}

export default EmployeeInfoPopup
