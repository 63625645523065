import {coinsRelatedApi} from "./base"


export const couponsApi = coinsRelatedApi.injectEndpoints({
    endpoints: (builder) => ({
        claimCoupon: builder.mutation({
            query: (data) => ({path: "coupons/claim", method: "POST", data}),
            invalidatesTags: ["BalanceAvailable"]
        }),
    })
})

export const {
    useClaimCouponMutation,
} = couponsApi
