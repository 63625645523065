import {formatDate, getTime} from "../../../utils/datetime.utils"
import React from "react"


const DateTimeColumn = ({ date }) => {
    return (
        <div style={{display: "flex", gap: 10}}>
            <span>{formatDate(date)}</span> <span>{getTime(date)}</span>
        </div>
    )
}

export default DateTimeColumn