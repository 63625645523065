
import React, {useState} from "react"
import {Helmet} from "react-helmet"
import AuditLogsList from "./components/AuditLogsList"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"


const AuditLogs = () => {
    const [userMessage, setUserMessage] = useState(null)

    return (
        <>
            <Helmet>
                <title>Audit Logs - Newsoft Inside</title>
            </Helmet>

            <div>
                {userMessage && <AlertMessage setMessage={setUserMessage} {...userMessage}/> }
            </div>

            <div className="audit-logs">
                <AuditLogsList setUserMessage={setUserMessage}/>
            </div>

        </>)
}

export default AuditLogs
