import React, {useState} from "react"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../constants/other"
import TextInput from "../../../toolkits/TextInput/TextInput"
import {useCreateProjectMutation, useUpdateProjectMutation} from "../../../api/projects"


const CreateEditProjectPopup = ({project, onSubmit, setUserMessage, close}) => {
    const [createProject] = useCreateProjectMutation()
    const [updateProject] = useUpdateProjectMutation()
    const [name, setName] = useState(project ? project.name : "")

    const handleSubmit = () => {
        const data = {
            name,
        }
        if (!project) {
            createProject(data)
                .unwrap()
                .then(() => {
                    onSubmit()
                    close()
                    setUserMessage({
                        message: `The project ${name} was successfully created`,
                        code: SUCCESS_CODE
                    })
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        } else {
            updateProject({id: project.id, data })
                .unwrap()
                .then(() => {
                    onSubmit()
                    close()
                    setUserMessage({
                        message: `The project ${name} was successfully updated`,
                        code: SUCCESS_CODE
                    })
                })
                .catch(error => {
                    setUserMessage({message: error.message, code: ERROR_CODE})
                })
        }
    }

    return (
        <CustomPopup text_title={project ? "Edit project" : "Create project"}>
            <div className="popup-content">
                <TextInput
                    text_label="Name"
                    placeholder=""
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit} disabled={!name}> {project ? "SAVE" : "CREATE"} </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default CreateEditProjectPopup
