import React from "react"
import telegramIcon from "../../assets/images/telegramIcon.svg"
import { reformatPhoneNumber } from "../../utils/other.utils"
import { TELEGRAM_URL } from "../../constants/other"

const TelegramButton = ({phone_number}) => {

    return (
        <a href={`${TELEGRAM_URL}${reformatPhoneNumber(phone_number)}`} target="_blank" rel="noreferrer">
            <img className="telegram-icon clickable" src={telegramIcon} alt="telegram logo"/>
        </a>
    )
}

export default TelegramButton
