import "./index.scss"

import React, {useMemo, useState, useEffect} from "react"
import Helmet from "react-helmet"
import InfiniteScroll from "react-infinite-scroll-component"

import TimeRecordsTable from "./components/TimeRecordsTable"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {FILTER_BY_PROJECTS_NOTE, FILTER_TYPES} from "../../constants/other"
import {getSavedTimeRecordsRange, getUserViewedAs} from "../../utils/localstorage.utils"
import {TimeRecordsPeriodFilter} from "./components/TimeRecordsPeriodFilter"
import {formatDate, mapDateRangeFilters} from "../../utils/datetime.utils"
import MultipleSelect from "../../toolkits/Multiselect"
import {useMediaQuery} from "react-responsive"
import {DESKTOP_WIDTH} from "../../constants/other"
import InfoMessage from "../../components/InfoMessage"
import useUserProjects from "../../hooks/useUserProjects"
import useTimeRecords from "../../hooks/useTimeRecords"
import {usePaginatedQuery} from "../../hooks/common/usePaginatedQuery"

export const TimeRecords = ({location}) => {
    const isMainPage = useMemo(() => location.pathname === "/time-record-requests", [location])
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const userId = getUserViewedAs() ? getUserViewedAs().user_id : location.state?.userId
    const savedTimeRecordsRange = getSavedTimeRecordsRange()

    const {projects} = useUserProjects({userId, all: true})
    const [selectedProjectIds, setSelectedProjectIds] = useState([])
    const [dateFilter, setDateFilter] = useState(Object.values(FILTER_TYPES).includes(savedTimeRecordsRange) ? mapDateRangeFilters(savedTimeRecordsRange) : mapDateRangeFilters(FILTER_TYPES.THIS_MONTH))
    const isAllProjectsSelected = useMemo(() => selectedProjectIds.length === projects.length || selectedProjectIds.length === 0, [projects, selectedProjectIds])

    const params = useMemo(() => {
        let params = {
            all: true,
            project_in: isAllProjectsSelected ? null : selectedProjectIds.toString(),
        }

        if (!isMainPage) {
            params = {
                created_after: formatDate(dateFilter.created_after),
                created_before: formatDate(dateFilter.created_before),
                project_in: isAllProjectsSelected ? null : selectedProjectIds.toString()
            }
        }
        return params
    }, [isMainPage, selectedProjectIds, dateFilter])
    
    const {
        isLoading,
        items,
        hasMore,
        loadNextPage,
        changeParams,
        reset,
    } = usePaginatedQuery(useTimeRecords, {defaultState: { params: { userId, ...params }}})
    
    useEffect(() => {
        changeParams(params)
    }, [changeParams, params])

    const hasProjects = projects.length !== 0

    const [userMessage, setUserMessage] = useState(null)
    const [approvedRecords, setApprovedRecords] = useState([])

    return (
        <>
            <Helmet>
                <title> {`Time Record${isMainPage ? " Requests" : "s"}`} - Newsoft Inside </title>
            </Helmet>
            <div className="time-records">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message}
                            code={userMessage.code}/>
                    }
                </div>
                {!isMainPage &&
                    <div className="time-records-filters">
                        <TimeRecordsPeriodFilter period={dateFilter} setPeriod={setDateFilter} userId={userId}/>
                        {isDesktop && hasProjects &&
                            <div className="time-records-filters-projects">
                                {selectedProjectIds.length !== 0 && selectedProjectIds.length !== projects.length &&
                                    <InfoMessage text={FILTER_BY_PROJECTS_NOTE}/>}
                                {projects.length > 1 &&
                                    <>
                                        <span className="time-records-filters-projects-filter-text t-s4">Project:</span>
                                        <MultipleSelect placeholder="Choose the project" loading={isLoading}
                                            options={projects.map(p => p.project)} selectedOptionIds={selectedProjectIds}
                                            setSelectedOptionIds={setSelectedProjectIds}/>
                                    </>
                                }
                            </div>
                        }
                    </div>
                }
                <InfiniteScroll
                    next={loadNextPage}
                    hasMore={hasMore}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={items.length - approvedRecords.length}
                    useWindow={false}
                >
                    {items.length > 0 && (
                        <div className="time-records-content">
                            <TimeRecordsTable
                                timeRecords={items.filter(record =>
                                    !approvedRecords.includes(record.id))
                                }
                                refreshTable={reset}
                                setUserMessage={setUserMessage}
                                isMainPage={isMainPage}
                                userId={userId}
                                setApprovedRecords={setApprovedRecords}
                            />
                        </div>
                    )}
                    {isLoading && <div><br/><StyledCircularProgress/></div>}
                </InfiniteScroll>
            </div>
        </>
    )
}

export default TimeRecords
