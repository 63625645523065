import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"

import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"

import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import {getColorByHoursType} from "../../../utils/other.utils"
import Select from "react-select"
import {MOBILE_WIDTH} from "../../../constants/other"
import {useMediaQuery} from "react-responsive"
import useTimeOffsUsageInfo from "../../../hooks/useTimeOffsUsageInfo"


const TimeOffUsageInfoPopup = ({employee, year, close}) => {
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})

    const currentYear = new Date().getFullYear()
    const [yearOptions, setYearOptions] = useState([])
    const [selectedYear, setSelectedYear] = useState({ value: year, label: `${year}` })

    const { timeOffsUsageInfo, uniqueYears, isLoading } = useTimeOffsUsageInfo({
        userId: employee.id,
        params: { year: selectedYear.value },
    })

    const totalDays = timeOffsUsageInfo?.time_offs?.reduce((total, record) => {
        return total + (record.approved === true ? Number(record.count) : 0)
    }, 0)

    useEffect(() => {
        const years = Array.from(uniqueYears, (year) => ({value: year.year, label: `${year.year}`}))
        setYearOptions(years)
        setSelectedYear({ value: year, label: `${year}` })
    }, [uniqueYears, dispatch, employee.id, year])

    const handleYearChange = async (selectedOption) => {
        setSelectedYear(selectedOption)
    }

    return (
        <FlexiblePopup>
            <div className="time-offs-popup">
                <div className="time-offs-popup-title t-h2">Time Off Usage</div>
                <div className={`time-offs-popup-subtitle ${isMobile ? "t-b4" : "t-b2"}`}>You earn about {timeOffsUsageInfo.earn_time_off_per_month} days of time off every month.</div>
                <div className="time-offs-popup-left">
                    <div className="time-offs-popup-left-info-container">
                        <span className={`time-offs-popup-left-info${timeOffsUsageInfo.left === 0 ? "-inactive" : ""} ${isMobile ? "t-b3" : "t-s6"}`}>
                            TOTAL PER YEAR: {timeOffsUsageInfo.total_per_year || 0} DAY{timeOffsUsageInfo.total_per_year != 1 ? "S": ""} {timeOffsUsageInfo.transferred ? <span className={`time-offs-popup-left-info-inactive ${isMobile ? "t-b3" : "t-s6"}`}>(TRANSFERRED {timeOffsUsageInfo.transferred || 0} DAY{timeOffsUsageInfo.transferred != 1 ? "S": ""})</span> : ""}
                        </span>
                        {selectedYear.value === currentYear &&
                            <span className={`time-offs-popup-left-info${timeOffsUsageInfo.left === 0 ? "-inactive" : ""} ${isMobile ? "t-b3" : "t-s6"}`}>
                                LEFT: {timeOffsUsageInfo.left || 0} DAY{timeOffsUsageInfo.left != 1 ? "S": ""}
                            </span>
                        }
                    </div>
                    <Select
                        isSearchable={false}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        options={yearOptions}
                        value={selectedYear}
                        onChange={handleYearChange}
                        classNamePrefix="custom-select t-s1 working-year-react-select"
                    />
                </div>
                <table className="time-offs-popup-table">
                    <thead className="time-offs-popup-table-head">
                        <tr className="time-offs-popup-table-row heading-color t-h3">
                            <th className="time-offs-popup-table-row-type">Type</th>
                            <th className="time-offs-popup-table-row-period">Period {isMobile && <span className="time-offs-popup-table-row-days-mobile">(Days)</span>}</th>
                            {!isMobile &&
                            <th className="time-offs-popup-table-row-days">Days</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <div className="time-offs-popup-loading"><StyledCircularProgress/></div>
                            :
                            <>
                                {timeOffsUsageInfo && timeOffsUsageInfo.time_offs.map((record, i) => (
                                    (<>
                                        <hr className={` ${ i===0 ? "first" : ""} time-offs-popup-table-divider`}/>
                                        <tr key={i} className="time-offs-popup-table-row t-b2">
                                            <td className="time-offs-popup-table-row-type t-b2"><li style={{backgroundColor: getColorByHoursType(record.type, record.approved)}} className="dot"></li>{record.type}</td>
                                            <td className="time-offs-popup-table-row-period t-b2">{record.period} {isMobile && <span className="time-offs-popup-table-row-days-mobile">({record.count})</span>}</td>
                                            {!isMobile &&
                                            <td className="time-offs-popup-table-row-days t-s4">{record.count} {record.approved === null && <p className="time-offs-popup-in-review t-b3">in review</p>}</td>
                                            }
                                        </tr>

                                    </>)
                                ))}
                                <hr className="time-offs-popup-table-divider"/>
                                <tr className="time-offs-popup-table-row t-b1">
                                    <td className="time-offs-popup-table-row-type">Total:</td>
                                    <td className="time-offs-popup-table-row-period"></td>
                                    <td className="time-offs-popup-table-row-days">{totalDays}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
                <ClosePopupIcon className="newcoins-popup-close" onClick={close}/>
            </div>
        </FlexiblePopup>
    )
}

export default TimeOffUsageInfoPopup
